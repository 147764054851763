
  import { Component, Vue } from "vue-property-decorator";
  import LzNavIcon from "./components/NavIcon.vue";
  import { DeviceLaptopIcon } from "vue-tabler-icons";
  import { LZ_MODULES } from "@/config/modules";
  import { namespace } from "vuex-class";

  const auth = namespace("auth");

  type Tools = string[];

  @Component({
    components: { LzNavIcon, DeviceLaptopIcon }
  })
  export default class Main extends Vue {
    @auth.State("tools")
    public tools: Tools;

    collapsed = false;

    get visibleLzModules() {
      return Object.keys(LZ_MODULES).reduce((lzModules, moduleKey) => {
        const { active } = LZ_MODULES[moduleKey as keyof typeof LZ_MODULES];
        if (active) {
          lzModules[moduleKey] = LZ_MODULES[moduleKey];
        }
        return lzModules;
      }, {} as typeof LZ_MODULES);
    }
  }
