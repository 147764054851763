var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"label-container"},[_c('label',{attrs:{"for":_vm.context.id}},[_vm._v(" "+_vm._s(_vm.context.label)),_c('span',{staticClass:"asterisk"},[_vm._v(_vm._s(this.required ? "*" : ""))])]),(_vm.tooltip)?_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.tooltip,
      classes: 'bubble',
      hideOnTargetClick: false,
      trigger: 'hover click',
      autoHide: false,
      delay: { show: 0, hide: 100 }
    }),expression:"{\n      content: tooltip,\n      classes: 'bubble',\n      hideOnTargetClick: false,\n      trigger: 'hover click',\n      autoHide: false,\n      delay: { show: 0, hide: 100 }\n    }"}],staticClass:"tooltip-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 100 100"}},[_c('path',{attrs:{"d":"M50,11A39,39,0,1,0,89,50,39.05,39.05,0,0,0,50,11Zm4,55a4,4,0,0,1-8,0V47a4,4,0,0,1,8,0ZM50,38a4,4,0,1,1,4-4A4,4,0,0,1,50,38Z"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }