
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      nameColor: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        text: "",
        color: ""
      };
    },
    models: [
      {
        data: "text",
        event: "models:text"
      },
      {
        data: "color",
        event: "models:color"
      }
    ],
    watch: {
      text(newVal: string) {
        this.$emit("models:text", newVal);
      },
      color(newVal: string) {
        this.$emit("models:color", newVal);
      }
    }
  };
