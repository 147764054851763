
  import { Component, Vue } from "vue-property-decorator";

  export const sections = [
    "Web",
    "Support",
    "Advisor",
    "SEO",
    "Content",
    "Events",
    "Blog",
    "Portfolio"
  ];

  @Component
  export default class Home extends Vue {
    videos = {
      Support: require("../assets/videos/support.mp4"),
      Advisor: require("../assets/videos/advisor.mp4"),
      Portfolio: require("../assets/videos/portfolio.mp4"),
      Events: require("../assets/videos/events.mp4"),
      Web: require("../assets/videos/web.mp4"),
      Blog: require("../assets/videos/shop.mp4")
    };

    cards = sections.map(section => ({
      title: this.$t(`training.home.cards.${section}.title`),
      subtitle: this.$t(`training.home.cards.${section}.subtitle`),
      buttons: {
        link: `Training${section}`,
        video: this.videos[section]
      }
    }));

    selectedVideo = "";
    selectVideo(src: string) {
      this.selectedVideo = src;
      const dialog = this.$el.querySelector(
        ".video-modal"
      ) as HTMLDialogElement;
      dialog.showModal();
    }
    handleBackdropClick(e: MouseEvent) {
      if (e.target === e.currentTarget) {
        this.selectedVideo = "";
        (e.currentTarget as HTMLDialogElement).close();
      }
    }
    closeDialog() {
      this.selectedVideo = "";
      const dialog = this.$el.querySelector(
        ".video-modal"
      ) as HTMLDialogElement;
      dialog.close();
    }
  }
