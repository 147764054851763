
  import { Component, Emit, Prop, Vue } from "vue-property-decorator";

  type ButtonType = "primary" | "secondary" | "tertiary" | "gradient";

  @Component({})
  export default class Button extends Vue {
    /**
     * Defines the type of the button which styles the button.
     * See {@link @/typing/ButtonType.ts} for further info.
     */
    @Prop({ default: "primary" })
    protected readonly type!: ButtonType;

    /**
     * If true, the button is full width styled..
     */
    @Prop({ default: false })
    protected readonly fullWidth!: boolean;

    @Prop({ default: false })
    protected readonly smaller!: boolean;
    /**
     * Emits the button click event.
     * @returns Void.
     */
    @Emit("click")
    onClick(): void {
      return;
    }

    /**
     * Returns the class or classes used in the button.
     * @returns String defining the classes.
     */
    get buttonClass(): string {
      const pre = "lz-button--";
      const classes: Array<string> = [];

      classes.push(this.type);

      if (this.fullWidth) {
        classes.push("full-width");
      }

      if (this.smaller) {
        classes.push("smaller");
      }

      return classes.map((cl: string) => pre + cl).join(" ");
    }
  }
