
  import { Component, Vue, Prop } from "vue-property-decorator";
  import { VueEditor } from "vue2-editor";

  @Component({ components: { VueEditor } })
  export default class Label extends Vue {
    @Prop({ required: true, default: {}, type: Object })
    context: Record<string, any>;

    @Prop({ required: false, default: "", type: String })
    tooltip: string;

    required = this.context.rules.some(
      ({ ruleName }) => ruleName === "required"
    );
  }
